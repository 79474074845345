import { Box, CircularProgress, Typography } from "@mui/material";

import AccordionBox from "components/Layouts/AccordionBox";
import styled from "styled-components";

const ErrorBox = styled(Box)(({ theme }) => ({
	backgroundColor: "white",
	padding: 16,
	borderRadius: 4,
	border: `1px solid grey`,
	marginTop: 2,
}));
function IntegrationName({
	integrationName = "Sample Integration",
	sapError = "sapError",
	lastAttempt = "2024-01-01 at 12:00",
	loading,
}) {
	return (
		<>
			<AccordionBox
				title={`${integrationName} - Status`}
				defaultExpanded
				id="integration-name-status"
			>
				{loading ? (
					<CircularProgress />
				) : (
					<ErrorBox>
						<Typography
							component="div"
							sx={{
								color: "text.secondary",
								fontSize: 14,
								mb: 3,
							}}
						>
							{sapError}
						</Typography>

						<Typography
							variant="body2"
							sx={{
								color: "text.secondary",
								mb: 1,
							}}
						>
							Error Syncing with {integrationName}
						</Typography>

						<Typography
							variant="body2"
							sx={{
								color: "text.secondary",
								fontSize: 12,
								mb: 7,
							}}
						>
							Last Attempt on {lastAttempt}
						</Typography>
					</ErrorBox>
				)}
			</AccordionBox>
		</>
	);
}

export default IntegrationName;
