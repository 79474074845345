import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
	backgroundColor: "#f5f5f9",
	color: "rgba(0, 0, 0, 0.87)",
	maxWidth: 220,
	fontSize: theme.typography.pxToRem(12),
	border: "1px solid #dadde9",
	whiteSpace: "pre-wrap",
}));

function WarningMessageWithErrorIcon({ message }) {
	let msg;
	if (typeof message === "object") {
		msg = message.map((x) => <p style={{ padding: 0, margin: 0 }}>{x}</p>);
	} else {
		msg = message;
	}
	return (
		<>
			<HtmlTooltip title={msg}>
				<ErrorIcon style={{ color: "#FFC107" }} />
			</HtmlTooltip>
		</>
	);
}

export default WarningMessageWithErrorIcon;
